import { useState, useRef, useEffect } from 'react';

const PADDINGS = 110;

const useSliding = (elementWidth, countElements) => {
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [distance, setDistance] = useState(0);
  const [totalInViewport, setTotalInViewport] = useState(0);
  const [viewed, setViewed] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);

  useEffect(() => {
    const containerWidth = containerRef.current.clientWidth - PADDINGS;
    const scrollWidth = containerRef.current.scrollWidth;

    setContainerWidth(containerWidth);
    setTotalInViewport(Math.floor(containerWidth / elementWidth));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current]);

  const handlePrev = () => {
    // setViewed(viewed - totalInViewport);
    // setDistance(distance + 300);
    containerRef.current.scrollTo({
      top: 0,
      left: Math.max(500, 0),
      behavior: 'smooth',
    });
    // containerRef.left = 300;
  };

  const handleNext = () => {
    // setViewed(viewed + totalInViewport);
    // console.log(distance);
    // setDistance(distance - 300);
    containerRef.current.scrollTo({
      top: 0,
      left: Math.max(500, 0),
      behavior: 'smooth',
    });
  };

  const slideProps = {
    style: { transform: `translate3d(${distance}px, 0, 0)` },
  };

  const hasPrev = distance < 0;
  const hasNext = viewed + totalInViewport < countElements;

  return { handlePrev, handleNext, slideProps, containerRef, hasPrev, hasNext };
};

export default useSliding;
