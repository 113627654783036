import React from 'react';
import './SliderWrapper.scss';

const SliderWrapper = ({
  children,
  handleMouseHoverIn,
  handleMouseHoverOut,
  handleMouseHover,
}) => (
  <div
    className='slider-wrapper'
    onMouseOver={handleMouseHover}
    onMouseEnter={handleMouseHoverIn}
    onMouseLeave={handleMouseHoverOut}
  >
    {children}
  </div>
);

export default SliderWrapper;
