import React from 'react';
import './LinkButton.scss';
import cx from 'classnames';

export default function LinkButton({
  link,
  title,
  type,
  radius,
  onClick,
  left,
}) {
  return (
    <>
      {link ? (
        <a href={link ? link : '#'} target='_blank' rel='noopener noreferrer'>
          <button
            className={cx('link_button', {
              transparent_background: type === 'transparent',
              color_background: type === 'color',
              border_radius_around: radius,
            })}
            style={{ marginLeft: left ? left : 0 }}
            onClick={onClick}
          >
            {title}
          </button>
        </a>
      ) : (
        <button
          className={cx('link_button', {
            transparent_background: type === 'transparent',
            color_background: type === 'color',
            border_radius_around: radius,
          })}
          style={{ marginLeft: left ? left : 0 }}
          onClick={onClick}
        >
          {title}
        </button>
      )}
    </>
  );
}
