import React, { useEffect, useState } from 'react';
import './Related.scss';

import back from '../../../assets/back.png';
import next from '../../../assets/next.png';
import { Img } from 'react-image';
import Spinner from '../../Spinner';
import IconArrowDown from '../../Icons/IconArrowDown';
import LinkButton from '../LinkButton/LinkButton';
import CTAButton from '../CTAButton/CTAButton';
export default function Related({ data, goToContact }) {
  const [showPrev, setShowPrev] = useState(null);

  useEffect(() => {
    const container = document.getElementById('related_container');
    container.addEventListener(
      'scroll',
      () => {
        if (container.scrollLeft > 100 && !showPrev) setShowPrev(true);
        if (container.scrollLeft < 100) setShowPrev(false);
      },
      { passive: true }
    );

    return () => {
      container.removeEventListener('scroll', () => {
        console.log('scroll unmount');
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='related-main-wrapper'>
      <div id='related_container' className='related_container'>
        <div className='banner-text'>
          {data.map((sc, index) => (
            <div
              key={index.toString()}
              className='related__content'
              onClick={() => window.open(sc.url)}
            >
              <Img
                src={[sc.image ? sc.image : '']}
                loader={
                  <div className='loader-wrapper'>
                    <Spinner />
                  </div>
                }
              />
              <div className='app-title-wrapper-related'>
                <div className='app-title-related'>{sc.title}</div>
              </div>
              <div className='related-show-arrow-down'>
                <IconArrowDown />
              </div>
            </div>
          ))}
        </div>
        {data.length > 3 ? (
          <>
            {showPrev && (
              <div
                className='related_container_prev'
                onClick={() => {
                  const container = document.getElementById(
                    'related_container'
                  );
                  container.scrollTo({
                    top: 0,
                    left: container.scrollLeft - 200,
                    behavior: 'smooth',
                  });
                }}
              >
                <img src={back} alt='' style={{ width: '30px' }} />
              </div>
            )}

            <div
              className='related_container_next'
              onClick={() => {
                const container = document.getElementById('related_container');
                container.scrollTo({
                  top: 0,
                  left: container.scrollLeft + 200,
                  behavior: 'smooth',
                });
              }}
            >
              <img src={next} alt='' style={{ width: '30px' }} />
            </div>
          </>
        ) : null}
      </div>
      <div className='related_cta_container'>
        <CTAButton
          text='Have an idea in mind?'
          buttonText='SUGGEST AN APP IDEA'
          onClick={goToContact}
        />
      </div>
    </div>
  );
}
