import React, { useState, useEffect } from 'react';
import IconArrowDown from './../Icons/IconArrowDown';
import './ShowDetailsButton.scss';
import { BASE_URL, SEELOGIC_COLOR } from '../../utils/constants';
import upwhite from '../../assets/like-white.png';
import upred from '../../assets/like-red.png';
import Axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import ColorThief from 'colorthief';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import Platforms from './Platforms/Platforms';

const ShowDetailsButton = ({ onClick, myapp }) => {
  const [dominantColor, setDominantColor] = useState('rgba(0,0,0,0.8)');
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [likedCount, setLikedCount] = useState(
    myapp.likes ? parseInt(myapp.likes) : 0
  );
  const [dislikedCount, setDislikedCount] = useState(
    myapp.dislikes ? parseInt(myapp.dislikes) : 0
  );

  const upvote = (e) => {
    e.stopPropagation();
    let url = `${BASE_URL}/wp-json/wp/v2/thumbs/${myapp.id}/likes`;
    if (liked) {
      url = `${BASE_URL}/wp-json/wp/v2/thumbs/${myapp.id}/likesdes`;
    }
    if (liked) {
      setLiked(false);
      setLikedCount((prevState) => {
        return prevState - 1;
      });
    } else {
      setLiked(true);
      setLikedCount((prevState) => {
        return prevState + 1;
      });
    }
    Axios.get(url)
      .then((dt) => {})
      .catch((error) => console.log(error));
  };

  const RGB_Log_Shade = (p, c) => {
    var i = parseInt,
      r = Math.round,
      [a, b, c, d] = c.split(','),
      P = p < 0,
      t = P ? 0 : p * 255 ** 2,
      P = P ? 1 + p : 1 - p;
    return (
      'rgb' +
      (d ? 'a(' : '(') +
      r((P * i(a[3] == 'a' ? a.slice(5) : a.slice(4)) ** 2 + t) ** 0.5) +
      ',' +
      r((P * i(b) ** 2 + t) ** 0.5) +
      ',' +
      r((P * i(c) ** 2 + t) ** 0.5) +
      (d ? ',' + d : ')')
    );
  };

  useEffect(() => {
    let colorThief = new ColorThief();
    const img = new Image();

    img.addEventListener('load', () => {
      const colorArray = colorThief.getColor(img, 5);
      const palette = colorThief.getPalette(img, 2, 5)[0];
      if (colorArray) {
        const color = `rgba(${palette[0]}, ${palette[1]}, ${palette[2]}, 0.8)`;
        const darker = RGB_Log_Shade(-0.5, color);
        setDominantColor(darker);
      }
    });
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // dev code
      let googleProxyURL =
        'https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=';
      img.crossOrigin = 'Anonymous';
      img.src = googleProxyURL + encodeURIComponent(myapp.image);
    } else {
      img.crossOrigin = 'Anonymous';
      img.src = myapp.image;
      // production code
    }

    return () => {
      img.removeEventListener('load', () => {});
    };
  }, [myapp.image]);

  const downvote = (e) => {
    e.stopPropagation();
    let url = `${BASE_URL}/wp-json/wp/v2/thumbs/${myapp.id}/dislikes`;
    if (disliked) {
      url = `${BASE_URL}/wp-json/wp/v2/thumbs/${myapp.id}/dislikesdes`;
    }

    if (disliked) setDisliked(false);
    else setDisliked(true);
    Axios.get(url)
      .then((dt) => {})
      .catch((error) => console.log(error));
  };

  return (
    <>
      <div
        onClick={onClick}
        className='show-details-button'
        style={{ background: dominantColor }}
      >
        <div className='details-wrapper'>
          <div className='upvote-downvote-wrapper'>
            {!disliked ? (
              <div className='upvote-container'>
                <span>{likedCount}</span>
                <button
                  onClick={(e) => upvote(e)}
                  className='upvote-downvote-up'
                  style={{
                    backgroundColor: liked ? SEELOGIC_COLOR : 'white',
                  }}
                >
                  <img src={liked ? upwhite : upred} alt='' />
                </button>
              </div>
            ) : null}
            {/* {!liked ? (
                <>
                  <span>{likedCount}</span>

                  <button
                    onClick={(e) => downvote(e)}
                    className='upvote-downvote-down'
                    style={{
                      backgroundColor: disliked
                        ? SEELOGIC_COLOR
                        : 'transparent',
                      transform: disliked
                        ? 'scale(1.2) rotate(180deg)'
                        : 'scale(1) rotate(180deg)',
                    }}
                  >
                    <img src={up} alt='' />
                  </button>
                </>
              ) : null} */}
          </div>
          <div className='details-info-container'>
            <div className='details-title-wrapper'>
              <h2 className='details-title'>{myapp.title}</h2>
            </div>
            <div className='details-bottom-wrapper'>
              <div className='details-bottom-left-wrapper'>
                <h3 className='tailored-title'>TAILORED FOR</h3>
                <p className='tailored-for'>{myapp.tailoredFor}</p>
              </div>

              {/* {myapp.priceRange ? (
              <>
                <div className='price-range-title'>PRICE RANGE</div>
                <div className='price-range-value'>{myapp.priceRange}</div>
              </>
            ) : null} */}
              <div className='details-bottom-right-wrapper'>
                <div>
                  {myapp.platform ? (
                    <>
                      <h3 className='platform-title'>PLATFORM</h3>
                      <div className='platform-container'>
                        <Platforms
                          platforms={myapp.platform}
                          height={24}
                          width={24}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div onClick={onClick} className='show-details-arrow-down'>
        <IconArrowDown />
      </div>
    </>
  );
};

export default ShowDetailsButton;
