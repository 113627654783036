import React from 'react';
import cx from 'classnames';
import './CTAButton.scss';

export default function CTAButton({ text, buttonText, onClick }) {
  return (
    <div className='cta_container'>
      <h2 className='cta_text'>{text}</h2>
      <button className={cx('cta_button', {})} onClick={onClick}>
        {buttonText}
      </button>
    </div>
  );
}
