import React from 'react';
import ContentLoader from 'react-content-loader';

const MyLoader = (props) => (
  <ContentLoader
    speed={2}
    width={1500}
    height={250}
    viewBox='0 0 1500 250'
    backgroundColor='#585555'
    foregroundColor='#747272'
    {...props}
  >
    <rect x='23' y='10' rx='2' ry='2' width='164' height='28' />
    <rect x='25' y='59' rx='2' ry='2' width='298' height='192' />
    <rect x='332' y='58' rx='2' ry='2' width='298' height='192' />
    <rect x='638' y='58' rx='2' ry='2' width='298' height='192' />
    <rect x='943' y='56' rx='2' ry='2' width='298' height='192' />
  </ContentLoader>
);

export default MyLoader;
