import React, { useEffect, useRef, useState } from 'react';
import './Screenshots.scss';
import back from '../../../assets/back.png';
import next from '../../../assets/next.png';
import { Img } from 'react-image';
import Spinner from '../../Spinner';
import cx from 'classnames';
import LinkButton from '../LinkButton/LinkButton';
import CTAButton from '../CTAButton/CTAButton';
import SliderContext from '../context';

export default function Screenshots({ data, goToContact }) {
  // const aa = [...data, ...data, ...data, ...data];

  const [showPrev, setShowPrev] = useState(false);
  // const [showNext, setShowNext] = useState(false);
  const [screenshotOrientation, setScreenshotOrientation] = useState(
    'vertical'
  );

  useEffect(() => {
    const img = new Image();

    img.addEventListener('load', () => {
      if (img.width > img.height) {
        setScreenshotOrientation('horizontal');
      }
    });

    img.src = data[0].url;
    const container = document.getElementById('screenshot_container');
    container.addEventListener(
      'scroll',
      () => {
        if (container.scrollLeft > 100 && !showPrev) setShowPrev(true);
        if (container.scrollLeft < 100) setShowPrev(false);

        // if (
        //   container.scrollLeft + window.innerWidth ===
        //   container.scrollWidth
        // ) {
        //   alert('bottom');
        // }
      },
      { passive: true }
    );

    return () => {
      container.removeEventListener('scroll', () => {
        console.log('scroll unmount');
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SliderContext.Consumer>
      {({ sliderTitle }) => {
        return (
          <div className='screenshot-main-wrapper'>
            <div
              id='screenshot_container'
              className={cx('screenshot_container', {
                'screenshot-horizontal': screenshotOrientation === 'horizontal',
                'screenshot-vertical': screenshotOrientation === 'vertical',
              })}
            >
              <div className='banner-text'>
                {data.map((sc) => (
                  <div key={sc.id} className='screenshot__content'>
                    <Img
                      src={[sc.url ? sc.url : '']}
                      loader={
                        <div className='loader-wrapper'>
                          <Spinner />
                        </div>
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
            {data.length > 3 ? (
              <>
                {showPrev && (
                  <div
                    className='screenshot_container_prev'
                    onClick={() => {
                      const container = document.getElementById(
                        'screenshot_container'
                      );

                      container.scrollTo({
                        top: 0,
                        left: container.scrollLeft - 200,
                        behavior: 'smooth',
                      });
                    }}
                  >
                    <img src={back} alt='' style={{ width: '30px' }} />
                  </div>
                )}

                <div
                  className='screenshot_container_next'
                  onClick={() => {
                    const container = document.getElementById(
                      'screenshot_container'
                    );

                    container.scrollTo({
                      top: 0,
                      left: container.scrollLeft + 200,
                      behavior: 'smooth',
                    });
                  }}
                >
                  <img src={next} alt='' style={{ width: '30px' }} />
                </div>
              </>
            ) : null}
            <div className='sc_cta_container'>
              <CTAButton
                text='Want to see the app in action?'
                buttonText='REQUEST A DEMO'
                onClick={goToContact}
              />
            </div>
          </div>
        );
      }}
    </SliderContext.Consumer>
  );
}
