import React from 'react';
import './Features.scss';
import CTAButton from '../CTAButton/CTAButton';

export default function Features({ data, goToContact }) {
  return (
    <div className='features_container'>
      <div className='features_wrapper'>
        <div className='features_title'>Benefits</div>
        <ul className='features_details'>
          {data.features.map((ft, index) => (
            <li className='features_details_item' key={index.toString()}>
              <img
                src={
                  ft.icon
                    ? ft.icon
                    : 'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSiGuFgmzT2mZX9MJeibgkDG7543HuMOHUiNbhNlQHQGWX9yHEZ&usqp=CAU'
                }
                alt='feature icon'
              />
              <p>{ft.description}</p>
            </li>
          ))}
        </ul>
        <div className='feature_cta_container'>
          <CTAButton
            buttonText='REQUEST A FEATURE'
            text='Want something specific to your organisation/industry?'
            onClick={goToContact}
          />
        </div>
      </div>
      <div className='features_image'>
        <img
          src={
            data.featuresSectionImage
              ? data.featuresSectionImage
              : 'https://giveuselife.org/wp-content/uploads/2017/10/real-mobile-2x-1400x770.png'
          }
          alt='feature'
        />
      </div>
    </div>
  );
}
