import React from 'react';
import './More.scss';
import LinkButton from '../LinkButton/LinkButton';
import CTAButton from '../CTAButton/CTAButton';

export default function More({ data, goToContact }) {
  return (
    <div className='more-outer'>
      <div className='more_main_wrapper'>
        <div className='more_container'>
          {data.buildCost ? (
            <div className='more_item'>
              <h3 className='more_item_cost_title'>ORIGINALLY BUILT FOR</h3>
              <p className='more_item_cost_value'>{data.buildCost}</p>
            </div>
          ) : null}
          {data.buildTime ? (
            <div className='more_item'>
              <h3 className='more_item_time_title'>BUILD TIME</h3>
              <p className='more_item_time_value'>{data.buildTime}</p>
            </div>
          ) : null}

          {data.technology ? (
            <div className='more_item'>
              <h3 className='more_item_technology_title'>TECHNOLOGY</h3>
              <p className='more_item_technology_value'>{data.technology}</p>
            </div>
          ) : null}

          {data.integration ? (
            <div className='more_item'>
              <h3 className='more_item_integration_title'>INTEGRATION</h3>
              <p className='more_item_integration_value'>{data.integration}</p>
            </div>
          ) : null}
        </div>
      </div>
      <div className='more_cta_container'>
        <CTAButton
          buttonText='REQUEST MORE DETAILS'
          text='Need additional information?'
          onClick={goToContact}
        />
      </div>
    </div>
  );
}
