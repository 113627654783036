import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import SliderContext from './context';
import Content from './Content';
import SlideButton from './SlideButton';
import SliderWrapper from './SliderWrapper';
import useSliding from './useSliding';
import useSizeElement from './useSizeElement';
import './Slider.scss';
import { useMediaQuery } from 'react-responsive';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

const Slider = ({ children, activeSlide, sliderTitle, total }) => {
  const [currentSlide, setCurrentSlide] = useState(activeSlide);
  const { width, elementRef } = useSizeElement();

  const [showPrev, setShowPrev] = useState(false);

  // const isMobile = useMediaQuery({ maxWidth: 767 });

  // const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isTabOrMobile = useMediaQuery({ maxWidth: 1023 });

  const { slideProps, containerRef } = useSliding(
    width,
    React.Children.count(children)
  );

  const handleSelect = (myapp) => {
    if (isTabOrMobile) {
      window.open(myapp.link, '_blank');
    } else {
      // const allContents = document.querySelectorAll('.content__close');
      // console.log(allContents);
      // if (allContents.length) {
      //   allContents.forEach((element) => {
      //     element.onclick = function () {
      //       console.log('object');
      //     };
      //   });
      // }
      setCurrentSlide(myapp);
      // console.log(containerRef.current.getBoundingClientRect().top);
      setTimeout(() => {
        let clickeItemPosition = containerRef.current.getBoundingClientRect()
          .top;
        window.scrollBy(0, clickeItemPosition - 100);
      }, 500);
    }
  };

  useEffect(() => {
    const container = containerRef.current.parentNode.parentNode.parentNode;
    container.addEventListener(
      'scroll',
      () => {
        if (
          containerRef.current.parentNode.parentNode.parentNode.scrollLeft >
            100 &&
          !showPrev
        )
          setShowPrev(true);
        if (
          containerRef.current.parentNode.parentNode.parentNode.scrollLeft < 100
        )
          setShowPrev(false);
      },
      { passive: true }
    );

    return () => {
      container.removeEventListener('scroll', () => {
        console.log('scroll unmount');
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef]);

  const handleClose = () => {
    setCurrentSlide(null);
  };

  const contextValue = {
    onSelectSlide: handleSelect,
    onCloseSlide: handleClose,
    sliderTitle: sliderTitle,
    elementRef,
    currentSlide,
  };

  return (
    <SliderContext.Provider value={contextValue}>
      {isTabOrMobile ? (
        <SliderWrapper>
          <div className='slider-inner-wrapper'>
            <div className='slider-mobile'>
              <div
                ref={containerRef}
                className='slider__container'
                {...slideProps}
              >
                {children}
              </div>
            </div>
          </div>
        </SliderWrapper>
      ) : (
        <>
          <div className='slider-main-container'>
            {total > 4 ? (
              <>
                {showPrev ? (
                  <SlideButton
                    onClick={() => {
                      // console.log(
                      //   containerRef.current.parentNode.parentNode.parentNode
                      // );
                      containerRef.current.parentNode.parentNode.parentNode.scrollTo(
                        {
                          top: 0,
                          left:
                            containerRef.current.parentNode.parentNode
                              .parentNode.scrollLeft - 200,
                          behavior: 'smooth',
                        }
                      );
                    }}
                    type='prev'
                  />
                ) : null}
                {
                  <SlideButton
                    onClick={() => {
                      // console.log(
                      //   containerRef.current.parentNode.parentNode.parentNode
                      //     .scrollLeft
                      // );
                      containerRef.current.parentNode.parentNode.parentNode.scrollTo(
                        {
                          top: 0,
                          left:
                            containerRef.current.parentNode.parentNode
                              .parentNode.scrollLeft + 200,
                          behavior: 'smooth',
                        }
                      );
                    }}
                    type='next'
                  />
                }
              </>
            ) : null}
            <SliderWrapper>
              <div className='slider-inner-wrapper'>
                <div
                  className={cx('slider', {
                    'slider--open': currentSlide != null,
                  })}
                >
                  <div
                    ref={containerRef}
                    className='slider__container'
                    {...slideProps}
                  >
                    {children}
                    {/*  added extra item to give space to last item while hover */}
                    <div className='item extra_space' />{' '}
                  </div>
                </div>
              </div>
            </SliderWrapper>
          </div>
          <SlideDown className={'my-dropdown-slidedown'}>
            {currentSlide ? (
              <Content
                myapp={currentSlide}
                initialtabIndex={0}
                onClose={handleClose}
              />
            ) : null}
          </SlideDown>{' '}
        </>
      )}

      {/* <CSSTransitionGroup
        transitionName='content'
        transitionEnterTimeout={500}
        transitionLeaveTimeout={300}
      >
        {currentSlide ? (
          <Content
            myapp={currentSlide}
            initialtabIndex={0}
            onClose={handleClose}
          />
        ) : null}
      </CSSTransitionGroup> */}
    </SliderContext.Provider>
  );
};

export default Slider;
