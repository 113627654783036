import React from 'react';
import Tippy from '@tippyjs/react';
import './Platforms.scss';

export default function Platforms({ platforms, height, width }) {
  return (
    <>
      {(platforms || []).map((pf, index) => (
        <div key={index.toString()} className='platform-wrapper'>
          {pf.title ? (
            <Tippy content={pf.title}>
              <div className='platform-value'>
                <img
                  src={pf.url ? pf.url : ''}
                  alt=''
                  width={width ? width : 30}
                  height={height ? height : 30}
                />
              </div>
            </Tippy>
          ) : (
            <div className='platform-value'>
              <img
                src={pf.url ? pf.url : ''}
                alt=''
                width={width ? width : 30}
                height={height ? height : 30}
              />
            </div>
          )}
        </div>
      ))}
    </>
  );
}
