import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const targets = document.querySelectorAll(".erw-root");
Array.prototype.forEach.call(targets, (target) => {
  const id = target.dataset.id;
  const settings = window.erwSettings[id];
  ReactDOM.render(<App settings={settings} />, target);
});

// const targets = document.querySelectorAll('.erw-root');
// Array.prototype.forEach.call(targets, (target) => {
//   ReactDOM.render(<App />, target);
// });

// ReactDOM.render(<App />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
