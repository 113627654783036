import React, { useState, useEffect } from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './Tabs.scss';
import Overview from './ContentPanels/Overview';
import Features from './ContentPanels/Features';
import Screenshots from './ContentPanels/Screenshots';
import More from './ContentPanels/More';
import Related from './ContentPanels/Related';
import Contact from './ContentPanels/Contact';

const ContentTabs = ({ myapp, onHandleTabChange, index }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [from, setFrom] = useState(null);
  const [contactIndex, setContactIndex] = useState(5);

  useEffect(() => {
    if (myapp.screenshots.length && myapp.relatedApps.length) {
      setContactIndex(5);
    } else if (myapp.screenshots.length || myapp.relatedApps.length) {
      setContactIndex(4);
    } else if (!myapp.screenshots.length && !myapp.relatedApps.length) {
      setContactIndex(3);
    }
  }, [myapp.relatedApps.length, myapp.screenshots.length]);

  useEffect(() => {
    setCurrentIndex(index);
    return () => {};
  }, [index]);

  return (
    <Tabs
      selectedIndex={currentIndex}
      onSelect={(index) => {
        setFrom(null);
        setCurrentIndex(index);
        onHandleTabChange(index);
      }}
    >
      <TabPanel>
        <Overview
          data={myapp}
          goToContact={(type) => {
            setCurrentIndex(contactIndex);
            setFrom(0);
          }}
        />
      </TabPanel>
      <TabPanel>
        <Features
          data={myapp}
          goToContact={(type) => {
            setCurrentIndex(contactIndex);
            setFrom(1);
          }}
        />
      </TabPanel>
      {myapp.screenshots && myapp.screenshots.length ? (
        <TabPanel>
          <Screenshots
            data={myapp.screenshots}
            goToContact={(type) => {
              setCurrentIndex(contactIndex);
              setFrom(2);
            }}
          />
        </TabPanel>
      ) : null}
      <TabPanel>
        <More
          data={myapp.moreDetails}
          goToContact={(type) => {
            setCurrentIndex(contactIndex);
            setFrom(3);
          }}
        />
      </TabPanel>
      {myapp.relatedApps && myapp.relatedApps.length ? (
        <TabPanel>
          <Related
            data={myapp.relatedApps}
            goToContact={(type) => {
              setCurrentIndex(contactIndex);
              setFrom(4);
            }}
          />
        </TabPanel>
      ) : null}
      <TabPanel>
        <Contact data={myapp} from={from} />
      </TabPanel>

      <TabList>
        <Tab>Overview</Tab>
        <Tab>Benefits</Tab>
        {myapp.screenshots && myapp.screenshots.length ? (
          <Tab>App Screenshots</Tab>
        ) : null}
        <Tab>More Details</Tab>
        {myapp.relatedApps && myapp.relatedApps.length ? (
          <Tab>Related Apps</Tab>
        ) : null}
        <Tab>Contact Us</Tab>
      </TabList>
    </Tabs>
  );
};

export default ContentTabs;
