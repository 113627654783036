import React, { useState, useEffect } from "react";
import Slider from "./components/NetflixSlider";
import "./App.scss";
import Axios from "axios";
import MyLoader from "./components/loading";
import SliderHeader from "./components/SliderHeader/SliderHeader";
import { BASE_URL } from "./utils/constants";

const App = ({ settings = { title: "Apps", cat: 8 } }) => {
  const [appData, setAppData] = useState([]);
  const [loadingAppData, setLoadingAddData] = useState(true);
  // const [categoryId, setCategoryId] = useState(8);
  // const [widgetTitle, setWidgetTitle] = useState('Featured apps');
  // if (settings && settings.cat && settings.title) {
  //   setCategoryId(settings.cat);
  //   setWidgetTitle(settings.title);
  // }
  useEffect(() => {
    setLoadingAddData(true);
    let url;
    if (settings.url) {
      url = settings.url;
    } else {
      url = `${BASE_URL}/wp-json/wp/v2/app?categories=5`;
    }

    Axios.get(url)
      .then((data) => {
        setLoadingAddData(false);
        // console.log(data);
        const mappedData = data.data.map((dt) => ({
          id: dt.id,
          description: dt.ACF.desciption,
          title: dt.title.rendered,
          image: dt.ACF.background_image.url
            ? `${dt.ACF.background_image.url}`
            : null,
          imageBg: `${dt.ACF.background_image.url}`,
          link: dt.link,
          appLogo: dt.ACF.app_logo ? dt.ACF.app_logo.url : "",
          tailoredFor: dt.ACF.tailored_for,
          priceRange: dt.ACF.price_range,
          platform: dt.platform_image,
          likes: dt.ACF.likes_thumb,
          dislikes: dt.ACF.dislikes_thumb,
          features: (dt.ACF.features || [])
            .filter((ft) => ft)
            .map((ft) => ({
              icon: ft.feactures_icon.url ? ft.feactures_icon.url : "",
              description: ft.feature_description,
            })),
          featuresSectionImage: dt.ACF.feature_section_image
            ? `${dt.ACF.feature_section_image.url}`
            : "",
          overviewImage: dt.ACF.overview_image
            ? `${dt.ACF.overview_image.url}`
            : "",
          screenshots: (dt.ACF.screenshots || [])
            .filter((sc) => sc.screenshot_gallery)
            .map((sc) => ({
              url: sc.screenshot_gallery.url,
              id: sc.screenshot_gallery.ID,
            })),
          moreDetails: {
            buildCost: dt.ACF.more_details_build_cost,
            buildTime: dt.ACF.more_details_build_time,
            integration: dt.ACF.more_details_integration,
            technology: dt.ACF.more_details_technology,
          },
          upcoming:
            dt.ACF.live_or_upcomming_app &&
            dt.ACF.live_or_upcomming_app !== "live",
          upcomingDetailsPageDisable:
            dt.ACF.disable_detail_page &&
            dt.ACF.disable_detail_page.length !== 0,
          relatedApps: (dt.ACF.related_apps || [])
            .filter((ra) => ra)
            .map((rp) => ({
              image: rp.background_image_realted_apps.url
                ? rp.background_image_realted_apps.url
                : "",
              url: rp.related_apps_url,
              title: rp.related_apps_title,
            })),
        }));
        console.log(mappedData);
        setAppData([...mappedData]);
      })
      .catch((error) => {
        setLoadingAddData(false);
        console.log(error);
      });
    return () => {};
  }, [settings.url]);

  return (
    <div className="app">
      {loadingAppData ? (
        <MyLoader />
      ) : (
        <>
          {appData.length ? (
            <>
              <SliderHeader name={settings.title} />
              <Slider sliderTitle={settings.title} total={appData.length}>
                {appData.map((myapp) => (
                  <>
                    <Slider.Item myapp={myapp} key={myapp.id}>
                      item1
                    </Slider.Item>
                  </>
                ))}
              </Slider>
            </>
          ) : null}
        </>
      )}
    </div>
  );
};

export default App;
