import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import SliderContext from './context';
import ShowDetailsButton from './ShowDetailsButton';
import Mark from './Mark';
import './Item.scss';
import { useMediaQuery } from 'react-responsive';

const Item = ({ myapp }) => {
  let timer = null;
  const isTabOrMobile = useMediaQuery({ maxWidth: 1023 });
  return (
    <SliderContext.Consumer>
      {({ onSelectSlide, currentSlide, elementRef }) => {
        const isActive = currentSlide && currentSlide.id === myapp.id;
        return (
          <div
            ref={elementRef}
            onMouseEnter={(e) => {
              timer = setTimeout(() => {
                onSelectSlide(myapp);
              }, 3000);
            }}
            onMouseLeave={(e) => {
              clearTimeout(timer);
            }}
            onClick={(e) => {
              e.stopPropagation();
              onSelectSlide(myapp);
            }}
            id={'siriusapp__' + myapp.id}
            className={cx('item', {
              'item--open': isActive,
            })}
          >
            <img className='item_blur_image' src={myapp.image} alt='' />
            {
              <div className='app-title-wrapper'>
                <div className='app-title'>{myapp.title}</div>
              </div>
            }
            {!isTabOrMobile ? (
              <>
                <ShowDetailsButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelectSlide(myapp);
                  }}
                  myapp={myapp}
                />
                {isActive && <Mark />}
              </>
            ) : null}
          </div>
        );
      }}
    </SliderContext.Consumer>
  );
};

export default Item;
