import React, { useState, useEffect, useContext } from 'react';
import cx from 'classnames';
import IconCross from './../Icons/IconCross';
import './Content.scss';
import ContentTabs from './Tabs';
import TabsContext from './TabsContext';
import Spinner from '../Spinner';
import open from '../../assets/open.png';
import SliderContext from './context';

const Content = ({ myapp, onClose, initialtabIndex }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const sliderContext = useContext(SliderContext);

  useEffect(() => {
    setTabIndex(initialtabIndex);
    return () => {};
  }, [initialtabIndex]);

  useEffect(() => {
    let timeout;
    if (!loaded) {
      timeout = setTimeout(() => {
        setLoaded(true);
      }, 500);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [loaded]);

  useEffect(() => {
    setLoaded(false);
    return () => {};
  }, [myapp]);

  return (
    <div
      className={cx('content', {
        'half-overlay': tabIndex === 0 || tabIndex === 1,
        'full-overlay':
          tabIndex === 2 || tabIndex === 3 || tabIndex === 4 || tabIndex === 5,
      })}
      style={{
        backgroundImage: `url(${myapp.imageBg})`,
        backgroundSize: 'cover',
      }}
    >
      <div className='content__area'>
        <div className='content__close'>
          {myapp.upcomingDetailsPageDisable ? null : (
            <div
              className='content_open_applink_container'
              onClick={() => window.open(myapp.link)}
            >
              <img src={open} alt='' height={40} width={40} />
            </div>
          )}

          <div className='content_close_image' onClick={onClose}>
            <IconCross />
          </div>
        </div>
        {!loaded ? (
          <div className='loader-wrapper-content'>
            <Spinner />
          </div>
        ) : (
          <ContentTabs
            index={0}
            onHandleTabChange={(index) => setTabIndex(index)}
            myapp={myapp}
          />
        )}
      </div>
    </div>
  );
};

export default Content;
