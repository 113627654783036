import React, { useState, useEffect, useContext } from 'react';
import contact from '../../../assets/contact.png';
import Axios from 'axios';
import Select from 'react-select';
import './Contact.scss';
import { BASE_URL } from '../../../utils/constants';
import Spinner from '../../Spinner';
import LinkButton from '../LinkButton/LinkButton';
import SliderContext from '../context';

export default function Contact({ data, from }) {
  const options = [
    { value: 'customisation', label: 'Request customisation' },
    { value: 'feature', label: 'Request a feature' },
    { value: 'demo', label: 'Request a demo' },
    { value: 'more', label: 'Request more details' },
    { value: 'idea', label: 'Suggest an app idea' },
  ];
  const optionsUpcoming = [
    { value: 'notify', label: 'Notify when available' },
    { value: 'feature', label: 'Request a feature' },
    // { value: 'demo', label: 'Request a demo' },
    { value: 'more', label: 'Request more details' },
    { value: 'idea', label: 'Suggest an app idea' },
  ];

  const sliderContext = useContext(SliderContext);
  // const [isUpcoming, setisUpcoming] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [type, setType] = useState(null);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [sending, setSending] = useState(false);
  const [actionOptions, setActionOptions] = useState(options);

  // useEffect(() => {
  //   console.log(from);
  //   setType(options[from]);
  //   return () => {};
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    const isUpcoming = data.upcoming;
    setActionOptions(isUpcoming ? optionsUpcoming : options);
    setType(isUpcoming ? optionsUpcoming[from] : options[from]);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const submitContactForm = () => {
    console.log('submit');

    if (name && email && company && type) {
      if (validateEmail(email)) {
        const url = `${BASE_URL}/wp-json/wp/v2/contact?id=${data.id}&name=${name}&email=${email}&company=${company}&demo=${type.value}&message=${message}`;
        setSending(true);
        setError('');
        setSuccess('');
        Axios.get(url)
          .then((dt) => {
            console.log(dt);
            setName('');
            setEmail('');
            setCompany('');
            setType(options[0]);
            setMessage('');
            setSending(false);
            setSuccess('Thank you for your response');
          })
          .catch((error) => {
            setSending(false);
            setError('Sorry, could not submit.Please try again');
            console.log(error);
          });
      } else {
        setError('Please enter valid email');
      }
    } else {
      setError('Some fields are missing. Please fill them and submit again');
    }
  };

  return (
    <div className='contact-main-wrapper'>
      <div className='contact-container'>
        <div className='contact-left-container'>
          <img className='contact-image' alt='' src={contact} />
        </div>
        <div className='contact-right-container'>
          <h3 className='contact-right-title'>
            Speak to us. Leave us a message and we will get right back to you
          </h3>

          <div className='contact-form'>
            <div className='form-row'>
              <div className='form-col'>
                <label>
                  <input
                    type='text'
                    name='name'
                    placeholder='Name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </label>
              </div>
              <div className='form-col'>
                <label>
                  <input
                    type='email'
                    name='email'
                    placeholder='Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </label>
              </div>
            </div>
            <div className='form-row'>
              <div className='form-col'>
                <label>
                  <input
                    type='company'
                    name='company'
                    placeholder='Company'
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </label>
              </div>
              <div className='form-col'>
                <Select
                  width={400}
                  // className='contact-select-style'
                  styles={{
                    indicatorSeparator: (provided, state) => ({}),
                    menu: (provided, state) => ({
                      ...provided,
                      backgroundColor: '#373737',
                    }),
                    dropdownIndicator: (provided, state) => ({
                      ...provided,
                      backgroundColor: '#373737',
                    }),
                    container: (provided, state) => ({
                      ...provided,
                      borderRadius: 0,
                      background: '#373737',
                      margin: '0px 0',
                      height: '40px',
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      background: '#373737',
                      padding: '2px 4px',
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      color: '#dedede',
                      padding: 5,
                      textAlign: 'left',
                      paddingLeft: 10,
                      borderRadius: 0,
                      backgroundColor: '#373737',
                      fontSize: '16px',
                    }),
                    control: (provided, state) => ({
                      // none of react-select's styles are passed to <Control />
                      ...provided,
                      backgroundColor: '#373737',
                      border: 'none',
                      paddingLeft: 5,
                      height: '40px',
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      color: '#dedede',
                      fontSize: '16px',
                      height: 'auto',
                    }),
                  }}
                  value={type}
                  onChange={(selected) => setType(selected)}
                  options={actionOptions}
                  placeholder='What do you want to do?'
                />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-col last-col'>
                <label>
                  <textarea
                    value={message}
                    placeholder='Message'
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </label>
              </div>
            </div>
            {error ? <div className='contact-submit-error'>{error}</div> : null}
            {success ? (
              <div className='contact-submit-success'>{success}</div>
            ) : null}
            {sending ? (
              <div className='spinner-container'>
                <Spinner />
              </div>
            ) : (
              <div className='submit_container'>
                <LinkButton
                  title='SUBMIT'
                  type='color'
                  onClick={() => submitContactForm()}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
