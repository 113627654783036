import React from 'react';
import './Overview.scss';
import 'tippy.js/dist/tippy.css';
import Platforms from '../Platforms/Platforms';
import LinkButton from '../LinkButton/LinkButton';
import SliderContext from '../context';
import CTAButton from '../CTAButton/CTAButton';

export default function Overview({ data, goToContact }) {
  return (
    <SliderContext.Consumer>
      {({ sliderTitle }) => {
        return (
          <div className='overview_container'>
            <div className='overview_container_details'>
              <div className='overview_wrapper'>
                <div className='overview_container_details_logo'>
                  <img
                    src={data.appLogo}
                    alt=''
                    style={{ height: '50px', width: '50px', borderRadius: 5 }}
                  />
                  <h2 className='overview_container_details_title'>
                    {data.title}
                  </h2>
                </div>
                <div className='overview_container_details_description'>
                  <p>{data.description}</p>
                </div>
                <div className='overview_link_container'>
                  {data.upcoming && data.upcomingDetailsPageDisable ? (
                    <LinkButton
                      title='NOTIFY WHEN AVAILABLE'
                      onClick={goToContact}
                      type='color'
                      radius={true}
                    />
                  ) : (
                    <LinkButton
                      title='VISIT APP PAGE'
                      link={data.link}
                      type='color'
                      radius={true}
                    />
                  )}
                </div>
                <div className='other_container'>
                  <div className='tailored_wrapper'>
                    <h3 className='tailored_title'>TAILORED FOR</h3>
                    <p>{data.tailoredFor}</p>
                  </div>
                  {data.platform ? (
                    <div className='platform_wrapper'>
                      <h3 className='platform_title'>PLATFORM</h3>
                      <div className='platform-container'>
                        <Platforms
                          platforms={data.platform}
                          height={30}
                          width={30}
                        />
                      </div>
                    </div>
                  ) : null}

                  {/* <div className='price_wrapper'>
              <div className='price_title'>STARTING FROM</div>
              <div>{data.priceRange}</div>
            </div> */}
                </div>
                {data.upcoming ? (
                  <>
                    {data.upcomingDetailsPageDisable ? null : (
                      <div className='cta_outer'>
                        <CTAButton
                          buttonText='GET NOTIFIED WHEN AVAILABLE'
                          onClick={goToContact}
                          text='Want to know when it&#39;s live?'
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <div className='overview_cta_container'>
                    <CTAButton
                      buttonText='REQUEST CUSTOMISATION'
                      text='Want to customise the app?'
                      onClick={goToContact}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className='overview_image'>
              <img
                src={
                  data.overviewImage
                    ? data.overviewImage
                    : 'https://giveuselife.org/wp-content/uploads/2017/10/real-mobile-2x-1400x770.png'
                }
                alt='overview'
              />
            </div>
          </div>
        );
      }}
    </SliderContext.Consumer>
  );
}
