import React from 'react';
import close from '../../assets/close.png';
const IconCross = () => (
  // <svg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'>
  //   <path
  //     fill='currentColor'
  //     d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'
  //   />
  // </svg>
  // <svg
  //   xmlns='http://www.w3.org/2000/svg'
  //   id='Capa_1'
  //   x='0px'
  //   y='0px'
  //   viewBox='0 0 47.971 47.971'
  // >
  //   <g>
  //     <g>
  //       <path
  //         d='M28.228,23.986L47.092,5.122c1.172-1.171,1.172-3.071,0-4.242c-1.172-1.172-3.07-1.172-4.242,0L23.986,19.744L5.121,0.88   c-1.172-1.172-3.07-1.172-4.242,0c-1.172,1.171-1.172,3.071,0,4.242l18.865,18.864L0.879,42.85c-1.172,1.171-1.172,3.071,0,4.242   C1.465,47.677,2.233,47.97,3,47.97s1.535-0.293,2.121-0.879l18.865-18.864L42.85,47.091c0.586,0.586,1.354,0.879,2.121,0.879   s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242L28.228,23.986z'
  //         data-original='#000000'
  //         data-old_color='#000000'
  //         fill='currentColor'
  //       />
  //     </g>
  //   </g>
  // </svg>
  <img src={close} alt='' height='100%' width='100%' />
);

export default IconCross;
